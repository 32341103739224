export const categories = [
  {
    id: 1,
    name: 'Clothing',
    subcategories: [
      // { name: 'Dresses', images: ["Dresses.jpg"] }, 
      { name: 'Tops', images: ["Tops.jpg"] }, 
      { name: 'T-shirts', images: ["T-shirts.jpg"] },
      { name: 'Capes', images: ["Capes.jpg"] }, 
      // { name: 'Pants', images: [] }, 
      { name: 'Belts', images: ["Belts.jpg"] }
    ]
  },
  {
    id: 2,
    name: 'Accesories',
    subcategories: [
      { name: 'Hair_Flowers',  images: ["HairFlowers.jpg"] },
      { name: 'Head_Bands', images: ["Headbands.jpg"] },
      { name: 'Brooches', images: ["Brooches.jpg"] }
    ]
  },
  {
    id: 3,
    name: 'Wigs',
    subcategories: [
      { name: 'Available_Wigs', images: ["Wigs.jpg"] },
      { name: 'Custom_Wigs', images: ["custom_wigs.jpg"] }
    ]
  }
]