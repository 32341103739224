import React, { useContext } from 'react'
import { ShopContext } from '../../context/ShopContext'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { categories } from '../../dummy'

const Header = () => {
    const context = useContext(ShopContext)

    const totalItems = () => {
        const total = context.cart.reduce((total, item) => { return total + item.quantity }, 0)
        return `(${total})`
    }

    return (
        <header>
            <Navbar fixed="top" expand="lg" variant="dark" bg="dark">
                <Navbar.Brand href="/">Glamerald</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {/* <Nav.Link href="/About">About</Nav.Link> */}
                        {categories.map((category, id) => {
                            return (
                                <NavDropdown key={id} title={category.name} id="basic-nav-dropdown">
                                    {category.subcategories.map((subcategory, i) => {
                                        return (<NavDropdown.Item key={i} href={`/Store/${subcategory.name}`}>{subcategory.name.replace("_", " ")}</NavDropdown.Item>)
                                    })}
                                </NavDropdown>
                            )
                        })}
                    </Nav>
                    <a href="/Cart">
                        <button className="btn-cart" >
                            <ion-icon name="cart-outline"></ion-icon>
                            <span>{totalItems()}</span>
                        </button>
                    </a>

                </Navbar.Collapse>
            </Navbar>
        </header>

    )
}

export default Header