import React from 'react'
import Header from './../components/Header'
import Footer from './../components/Footer'

const HomepageLayout = props => {
    return (
        <div className="fullHeight">
            <Header />
            <div className="mid-section">
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default HomepageLayout