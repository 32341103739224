import React, { useContext, useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { ShopContext } from '../../context/ShopContext'
import './Details.css'

const ProductDetails = () => {

    const { products, cart, reducers } = useContext(ShopContext)
    const [product, setProduct] = useState({})

   

    useEffect(() => {
        const [urlstring, store, subcategory, id] = window.location.pathname.split('/')
        const category = products.find(sub => sub.subcategory === subcategory.toLowerCase())
        const productRef = category.products.find(p => p.id === id)
        setProduct(productRef)
    }, [])

    useState(() => {
        console.log(cart)
    }, [cart])

    const ProductSize = ({ product }) => {
        if (!product.sizes) return null
        if (product.selectedSize.toLowerCase() === 'unique') return null
        return (
            <>
                <h3 className="product-section-heading">Select Size</h3>
                <div className="sizes-section">
                    {product.sizes.map((size, i) => {
                        if (size.name.toLowerCase() === 'unique') return null
                        if (product.selectedSize === size.name) return (<p key={i} className="product-size-selected" onClick={() => setProduct({ ...product, selectedSize: size.name })}>{size.name}</p>)
                        return (<p key={i} className="product-size" onClick={() => setProduct({ ...product, selectedSize: size.name })}>{size.name}</p>)
                    })}
                </div>
            </>
        )
    }

    const ProductStock = ({ product }) => {
        if (!product.sizes) return null
        return (
            <div className="stock-section">
                {product.sizes.map((size, i) => {
                    if (size.name === product.selectedSize) return (
                        <>
                            {size.stock === 0 ? <h3>Sold Out</h3> : null}
                        </>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="Product-Details">
            <div className="main-container">
                <div className="product-preview">

                    <Carousel className="carousel-product">
                        {product.images ? product.images.map((image, i) =>
                        (
                            <Carousel.Item key={i}>
                                <img style={{maxWidth: '400px', height: 'auto'}} src={image.src} alt={image.name} />
                            </Carousel.Item>
                        )
                        )
                            : ''
                        }
                    </Carousel>
                </div>

                <div className="product-data">
                    <h1 className="product-title">{product.name}</h1>
                    <p className="product-description">{product.description}</p>
                    <div className="product-prices">
                        <h3>Price: <span>{product.price}</span>€</h3>
                    </div>

                    {product.sizes &&
                        <>
                            <ProductSize product={product} />
                            <ProductStock product={product} />
                        </>
                    }

                    {cart.find(item => (item.id === product.id && item.selectedSize === product.selectedSize)) ?
                        <button
                            onClick={() => {
                                reducers.increaseProduct(product)
                            }}
                            className="btn mb-4 btn-primary">Add more
                        </button>
                        :
                        <button
                            onClick={() => {
                                reducers.addProductToCart(product)
                            }}
                            className="btn btn-primary">Add to cart
                        </button>
                    }

                    <div className="redirect">
                        <a href="/">Home</a>
                        <span>/</span>
                        <a href={`/Store/${product.category}`}>{product.category}</a>
                    </div>
                </div>



            </div>
        </div >
    )
}

export default ProductDetails