import React from 'react'

const PaymentConfirmation = ({ order }) => {
    
    return (
        <div className="container mt-5">
            <h3>ORDER CONFIRMATION</h3>
            <p>{order.customer.name}, thank you for your order!</p>
            <p>We will send you an email with your purchase information.</p>
        </div>
    )
}

export default PaymentConfirmation