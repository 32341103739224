import React, { useContext, useEffect } from 'react';
import { ShopContext } from '../../context/ShopContext'
import { Card, Container } from 'react-bootstrap';
import CartItem from './CartItem';

const CartProducts = () => {

    const { products, cart } = useContext(ShopContext)

    return (
        <div className="container">
            <Card body className="border-0"></Card>
            {cart.map((product,i) => {
                return <CartItem key={i} product={product} />
            })}
        </div>
    );
}

export default CartProducts;