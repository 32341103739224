import React from 'react'
// import { Nav, Container, Navbar, NavDropdown, Button } from 'react-bootstrap'

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer className="footer">
                    <p>© 2020 Glamerald.es <a href="/terms">Terms and Conditions</a></p>
                </footer>
            </div>
        )
    }

}
export default Footer