import React, { useContext, useEffect, useState } from 'react'
import { ShopContext } from '../../context/ShopContext'
import PaymentConfirmation from './PaymentConfirmation'
import { Card } from 'react-bootstrap'
import './Checkout.css'

import PayPal from './PayPal'
import { useHistory } from 'react-router-dom'

const Payment = ({ totalItems, totalPrice }) => {

    const getDate = () => {
        var fullDate = new Date()
        var day = fullDate.getDay()
        var month = fullDate.getMonth()
        var year = fullDate.getFullYear()
        return `${day}/${month}/${year}`
    }

    const { cart } = useContext(ShopContext)
    const history = useHistory()
    const [order, setOrder] = useState({ totalItemsPrice: totalPrice, status: 'init', date: getDate(), products: cart, shipment: 25, customer: {} })
    const [validated, setValidated] = useState(false)

    const [paidFor, setPaidFor] = useState(false)
    const [paidError, setPaidError] = useState(null)

    const stringValidation = (fieldName, fieldValue) => {
        if (typeof fieldValue === 'undefined') return false
        if (fieldValue.trim() === '') return `${fieldName} is required`
        if (/[^a-zA-Z -]/.test(fieldValue)) return 'Invalid characters'
        if (fieldValue.trim().length < 3) return `${fieldName} needs to be at least three characters`
        return true
    };

    const emailValidation = (email) => {
        if (typeof email === 'undefined') return false
        if (email.trim() === '') return 'Email is required'
        if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return true
        return 'Please enter a valid email'
    };

    const streetValidation = (street) => {
        if (typeof street === 'undefined') return false
        if (street.trim() === '') return 'Line is required'
        if (street.trim().length < 3) return 'Line needs to be at least three characters'
        return true
    }


    const error = {
        name: stringValidation('Name', order.customer.name),
        email: emailValidation(order.customer.email),
        line: streetValidation(order.customer.address?.line),
        city: stringValidation('City', order.customer.address?.city),
        state: stringValidation('State', order.customer.address?.state),
        country: stringValidation('Country', order.customer.address?.country)
    }

    useEffect(() => {
    }, [error])

    if (paidFor) {
        return <PaymentConfirmation order={order} />
    }

    if (paidError) {
        return
    }

    return (
        <div className="container">
            <h1 className="title">You are in the final step!</h1>
            <a href="/cart"><p>Go Back</p></a>
            <Card className="contact-card">
                <Card.Body>
                    <form>
                        <div className="row form-group justify-content-start">
                            <label className="col-sm-4 col-form-label text-left">First Name</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" placeholder="Your first name..." onChange={e => setOrder({ ...order, customer: { ...order.customer, name: e.target.value } })} />
                                {error.name ? <div className="mt-2" style={{ color: 'red' }}>{error.name}</div> : null}
                            </div>
                        </div>
                        <div className="row form-group justify-content-start">
                            <label className="col-sm-4 col-form-label text-left">Email</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" placeholder="Your email..." onChange={e => setOrder({ ...order, customer: { ...order.customer, email: e.target.value } })} />
                                {error.email ? <div className="mt-2" style={{ color: 'red' }}>{error.email}</div> : null}
                            </div>

                        </div>

                        <div className="row form-group justify-content-start">

                            <label className="col-sm-4 col-form-label text-left">Street and house number</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" placeholder="100 Main Street" onChange={e => setOrder({ ...order, customer: { ...order.customer, address: { ...order.customer.address, line: e.target.value } } })} />
                                {error.line ? <div className="mt-2" style={{ color: 'red' }}>{error.line}</div> : null}
                            </div>
                        </div>

                        <div className="row form-group justify-content-start">

                            <label className="col-sm-4 col-form-label text-left">Apartment, suite, etc. (optional)</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" onChange={e => setOrder({ ...order, customer: { ...order.customer, address: { ...order.customer.address, apartment: e.target.value } } })} />
                            </div>
                        </div>

                        <div className="row form-group justify-content-start">

                            <label className="col-sm-4 col-form-label text-left">City</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" placeholder="Los Ángeles" onChange={e => setOrder({ ...order, customer: { ...order.customer, address: { ...order.customer.address, city: e.target.value } } })} />
                                {error.city ? <div className="mt-2" style={{ color: 'red' }}>{error.city}</div> : null}
                            </div>
                        </div>

                        <div className="row form-group justify-content-start">

                            <label className="col-sm-4 col-form-label text-left">State/Province</label>
                            <div className="col-xl-8">
                                <input className="form-control" type="text" placeholder="California" onChange={e => setOrder({ ...order, customer: { ...order.customer, address: { ...order.customer.address, state: e.target.value } } })} />
                                {error.state ? <div className="mt-2" style={{ color: 'red' }}>{error.state}</div> : null}
                            </div>
                        </div>

                        <div className="row form-group justify-content-start">

                            <label className="col-sm-4 col-form-label text-left">Region</label>
                            <div className="col-xl-8">
                                <select id="region" className="form-control" type="text" value={order.shipment} onChange={e => {
                                    const region = document.getElementById("region").options[e.target.selectedIndex].text
                                    if (region !== 'Spain' && region !== 'US') return setOrder({ ...order, shipment: parseInt(e.target.value) })
                                    return setOrder({...order, shipment: parseInt(e.target.value), customer: {...order.customer, address: { ...order.customer.address, country: region }}})
                                }}>
                                    <option value={10}>Spain</option>
                                    <option value={15}>Europe</option>
                                    <option value={25}>US</option>
                                    <option value={35}>Rest of the world</option>
                                </select>
                            </div>
                        </div>

                        {order.shipment !== 10 && order.shipment !== 25 ?
                            <div className="row form-group justify-content-start">
                                <div className="col-xl-8">
                                    <input className="form-control" type="text" placeholder="Country" onChange={e => setOrder({ ...order, customer: { ...order.customer, address: { ...order.customer.address, country: e.target.value } } })} />
                                    {error.country ? <div className="mt-2" style={{ color: 'red' }}>{error.country}</div> : null}
                                </div>
                            </div> : null}


                    </form>
                </Card.Body>
            </Card>

            <Card className="resume-card">
                <Card.Body>
                    <h4 className="mb-3">Total Items: {totalItems}</h4>
                    <h4 className="mb-3">Total Price: {totalPrice} €</h4>
                    <h4 className="mb-3">Shipment: {order.shipment} €</h4>
                    <hr className="my-4" />
                    <h3 className="m-0 txt-right">Total Payment: {totalPrice + order.shipment} €</h3>
                </Card.Body>
            </Card>

            <Card className="paypal-card">
                {error.name === true && error.email === true && error.line === true && error.city === true && error.state === true && error.country === true ?
                    <PayPal order={order} total={totalPrice + order.shipment} setPaidFor={setPaidFor} setError={setPaidError} />
                    : null}
            </Card>
        </div >
    )
}

export default Payment