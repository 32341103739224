import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../context/ShopContext'
import { Container } from 'react-bootstrap';
import ProductItem from './ProductItem';

import './Store.css'

const ProductsGrid = ({ type }) => {

    const { products, reducers } = useContext(ShopContext)
    const [subcategory, setSubcategory] = useState({})

    useEffect(() => {
        const subcategoryRef = products.find(category => category.subcategory === type.toLowerCase())
        if (typeof subcategoryRef !== 'undefined' && subcategoryRef.products.length > 0) return setSubcategory(subcategoryRef)
        reducers.fetchProducts(type.toLowerCase())
    }, [])

    useEffect(() => {
        const subcategoryRef = products.find(category => category.subcategory === type.toLowerCase())
        setSubcategory(subcategoryRef)
    }, [products])

    return (
        <Container fluid>
            <div className="counter-search">
                {/* <input type="text" placeholder="Search product" className="mb-2 form-control" /> */}
                <p >{typeof subcategory !== 'undefined' ? subcategory.products?.length : 0} Products</p>
            </div>
            <div className="products-grid">
                {typeof subcategory !== 'undefined' ? subcategory.products?.map(product => (
                    <ProductItem key={product.id} product={product} />
                )) : null}
            </div>

        </Container>
    )
}

export default ProductsGrid;