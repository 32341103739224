import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { firebaseConfig } from './config'

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
const firestore = firebase.firestore()

class Product {
    constructor(id, name, description, price, category, selectedSize, sizes, images) {
        this.id = id
        this.name = name
        this.description = description
        this.price = price
        this.category = category
        this.selectedSize = selectedSize
        this.sizes = sizes
        this.images = images
    }
}

var productConverter = {
    toFirestore: function (product) {
        return {
            name: product.name,
            description: product.description,
            price: product.price,
            category: product.category,
            selectedSize: product.selectedSize,
            sizes: product.sizes,
            images: product.images,
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new Product(snapshot.id, data.name, data.description, data.price,
            data.category, data.sizes[0].name, data.sizes, data.images)
    }
}

const products = firestore.collection("products")

export function getListOfProductsByCategory(category, callback) {
    console.log(category)
    products.where("category", "==", category).withConverter(productConverter).get()
        .then(snapshot => {
            let products = []
            snapshot.forEach(snapshotDoc => {
                const data = snapshotDoc.data()
                products.push(new Product(snapshotDoc.id, data.name, data.description, data.price,
                    data.category, data.sizes[0].name, data.sizes, data.images))
            })
            callback(products)
        })
        .catch(err => console.log(err))
}

export function getProductDetails(id, callback) {
    products.doc(id).withConverter(productConverter).get()
        .then(snapshot => {
            callback(snapshot.data())
        })
}

const orders = firestore.collection("orders")

export const setOrder = async (order, callback) => {
    orders.doc().set(order)
        .then(console.log('OK'))
        .catch(err => console.log(err))
}

export default firebase