import React, { useContext, useEffect, useRef, useState } from 'react'
import { ShopContext } from '../../context/ShopContext'
import { setOrder } from '../../firebase/utils'
import { auth } from '../../firebase/utils'

const PayPal = ({ order, total, setPaidFor, setError }) => {

    const { reducers } = useContext(ShopContext)
    const paypal = useRef()

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: "Glamerald.es marvelous products",
                            amount: {
                                currency_code: "EUR",
                                value: total,
                            },
                        },
                    ],
                }
                )
            },
            onApprove: async (data, actions) => {
                order = { ...order, paypalInfo: await actions.order.capture() }
                reducers.clearAll()
                
                setOrder(order, () => {
                    auth.signOut()
                })
                setPaidFor(true)
            },
            onError: (err) => {
                setError(err)
                console.log(err)
            },
            onCancel: (data) => {
                return window.location = '/'
            }
        }).render(paypal.current)
    }, [])

    return (
        <div className="PayPal-page">
            <div ref={paypal}></div>
        </div>
    )

}

export default PayPal