export const CARTACTIONS = {
    ADD_ITEM: 'add-item',
    REMOVE_ITEM: 'remove-item',
    CLEAR_ALL: 'clear-all',
    INCREASE_ITEM: 'increase-item',
    DECREASE_ITEM: 'decrease-item',
    CHECK_OUT: 'check-out'
}

export const CartReducer = (cartState, action) => {

    switch (action.type) {
        case CARTACTIONS.ADD_ITEM:
            return { ...cartState, cart: [...cartState.cart, { id: action.payload.id, name: action.payload.name, price: action.payload.price, quantity: 1, selectedSize: action.payload.selectedSize, src: action.payload.src }] }

        case CARTACTIONS.REMOVE_ITEM:
            return {
                ...cartState,
                cart: cartState.cart.filter(item => 
                    item.id !== action.payload.item && item.selectedSize !== action.payload.selectedSize
                )
            }

        case CARTACTIONS.INCREASE_ITEM:
            return {
                ...cartState, cart: cartState.cart.map(item => {
                    if (item.id === action.payload.id && item.selectedSize === action.payload.selectedSize) {
                        return { ...item, quantity: item.quantity + 1 }
                    }
                    return item
                })
            }
        case CARTACTIONS.DECREASE_ITEM:
            return {
                ...cartState, cart: cartState.cart.map(item => {
                    if (item.id === action.payload.id && item.selectedSize === action.payload.selectedSize) {
                        return { ...item, quantity: item.quantity - 1 }
                    }
                    return item
                })
            }

        case CARTACTIONS.CLEAR_ALL:
            return { ...cartState, cart: [] }

        default:
            return cartState

    }
}