import React, { useContext, useState } from 'react'
import { ShopContext } from '../../context/ShopContext'
import { auth } from '../../firebase/utils'
import { Card, Col } from 'react-bootstrap'

import CartProducts from './CartProducts';
import Payment from '../payment/Payment';
import './Cart.css'



const Cart = () => {
    const { cart, reducers } = useContext(ShopContext)

    const [payment, setPayment] = useState(false)


    const totalItems = cart.reduce((total, item) => { return total + item.quantity }, 0)

    const totalPrice = cart.reduce((total, item) => { return Math.round((total + item.quantity * item.price) * 100) / 100 }, 0)

    const handlePayment = () => {
        auth.signInAnonymously()
        setPayment(true)
    }

    return (
        <div className="Cart">
            {payment ? <Payment totalItems={totalItems} totalPrice={totalPrice} />
                :
                <>
                    <div className="title-section">
                        <h1>Cart</h1>
                    </div >
                    {cart.length ?
                        <div className="cart-section">
                            <CartProducts />
                            <Card as={Col} className="sm-3 p-3">
                                <Card.Body>
                                    <p className="mb-1">Total Items</p>
                                    <h4 className="mb-3 txt-right">{totalItems}</h4>
                                    <p className="mb-1">Resume</p>
                                    <h3 className="m-0 txt-right">{totalPrice} € + shipping</h3>
                                    <hr className="my-4" />
                                    <div className="container">
                                        <p>Currently online shopping is not available. Please contact glamerald.es@gmail.com to place your order. We will need your full name, shipping address, and order details.</p>
                                        {/* <button type="button" className="btn-cart mr-3" onClick={() => handlePayment()}>CHECKOUT</button> */}
                                        {/* <button type="button" className="btn btn-outlineprimary btn-sm" onClick={() => reducers.clearAll()}>CLEAR</button> */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        :
                        <h1>No items</h1>
                    }
                </>
            }
        </div>
    )
}

export default Cart