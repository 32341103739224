import React from 'react'

const Terms = () => {
    return (
        <div className="container">
            <h1>Shipping and Return Policy</h1>
            <div className="policy-container">
                <h3>General Terms and Conditions</h3>
                <p>
                    The presentation of the product range on the Internet by the seller does not constitute a legally binding offer, but is only to be understood as an invitation to the customer to make a binding purchase offer. The contract is concluded according to the following conditions: a. You submit your contract offer with your order in the online shop. When ordering in the online shop, you must click on the “Pay” button after checking and confirming the entries for the ordered goods and after entering your personal data, including information on the method of payment, as well as checking and, if necessary, correcting your entries. We accept your contract offer by delivering the ordered goods. If you have given us a valid e-mail address, you will receive a confirmation of your order by e-mail. This order confirmation does not constitute a declaration of acceptance.
                    You can and should save these terms and conditions as well as any other order / contract data sent with the order confirmation and / or print them out. The order / contract data is saved by us. Should you lose your order / contract data, we will be happy to send you a copy of your order / contract data on request.
                </p>
            </div>
            <div className="cancel-container mt-4">
                <h3>Cancellation Policy</h3>
                <p>
                    You can revoke your contract declaration within 7 days of receipt of the goods without giving reasons in text form (e-mail). The timely sending of the cancellation is sufficient to meet the cancellation deadline. Custom-made products are excluded from exchange and return.
                    The revocation must be sent to: glamerald.es@gmail.com.
                    When exercising the right of withdrawal, you must send the received delivery within 14 days at your own expense to the address we will provide you when you send your revocation by email.  You will receive the purchase price back, but not the shipping costs. If the item sent has already been worn, the retail price cannot be refunded.
                    In order to guarantee a smooth handling of your return and to be able to accelerate the return handling process, it is advisable to provide the order number of the purchase process when returning your package. Due to the order number, the return process can run smoothly.
                </p>
            </div>
            <div className="other-container">
                <h3>Shipping costs, Fees</h3>
                <ul>
                    <li>As a rule, the goods are shipped promptly if in stock to the specified delivery address up to max. 5 working days from receipt of payment. The delivery is carried out by Correos Post certified service.</li>
                    <li>Please do not forget that the accessories are made by hand and if they are not in stock it will take 1-2 weeks to dispatch.</li>
                    <li>There are costs for the dispatch and packaging of the ordered goods, unless otherwise specified (e.g. within the scope of so-called shipping free campaigns). The shipping costs stated in the context of the product presentation and / or offer description apply.</li>
                </ul>
            </div>
        </div>
    )
}

export default Terms