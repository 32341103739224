import React, { useEffect, useReducer } from 'react';
import { ShopContext } from './ShopContext'
import { getListOfProductsByCategory } from '../firebase/utils'
import { CARTACTIONS, CartReducer } from '../reducers/CartReducer'
import { PRODUCTSACTIONS, ProductsReducer } from '../reducers/ProductsReducer'

const GlobalState = props => {

    // useEffect(() => {
    //     if (typeof productsState.products !== 'undefined' && productsState.products.length > 0) return console.log('ok')
    //     return fetchProducts("tops")
    // }, [])

    const initialStateProducts = () => {
        if (!JSON.parse(sessionStorage.getItem("products"))) return []
        if (JSON.parse(sessionStorage.getItem("products")).length !== 0) return JSON.parse(sessionStorage.getItem("products"))
        return []
    }

    const [productsState, dispatchProducts] = useReducer(ProductsReducer, { products: initialStateProducts() })

    useEffect(() => {
        sessionStorage.setItem('products', JSON.stringify(productsState.products))
    }, [productsState.products])

    const fetchProducts = (category, callback) => {
        getListOfProductsByCategory(category, productsRef => {
            dispatchProducts({ type: PRODUCTSACTIONS.FETCH_PRODUCTS, payload: { category: category, products: productsRef } })
        })
    }

    const initialStateCart = () => {
        if (!JSON.parse(sessionStorage.getItem("cart"))) return []
        if (JSON.parse(sessionStorage.getItem("cart")).length !== 0) return JSON.parse(sessionStorage.getItem("cart"))
        return []
    }

    const [cartState, dispatchCart] = useReducer(CartReducer, { cart: initialStateCart() })

    useEffect(() => {
        sessionStorage.setItem('cart', JSON.stringify(cartState.cart))
    }, [cartState.cart])

    const addProductToCart = product => {
        dispatchCart({ type: CARTACTIONS.ADD_ITEM, payload: { id: product.id, name: product.name , price: product.price, selectedSize: product.selectedSize, src: product.images[0].src } })
    }

    const increaseProduct = product => {
        dispatchCart({ type: CARTACTIONS.INCREASE_ITEM, payload: { id: product.id, selectedSize: product.selectedSize } })
    }

    const decreaseProduct = product => {
        dispatchCart({ type: CARTACTIONS.DECREASE_ITEM, payload: { id: product.id, selectedSize: product.selectedSize } })
    }

    const removeProductFromCart = product => {
        dispatchCart({ type: CARTACTIONS.REMOVE_ITEM, payload: { id: product.id, selectedSize: product.selectedSize } })
    }

    const clearAll = () => {
        dispatchCart({ type: CARTACTIONS.CLEAR_ALL })
    }

    return (
        <ShopContext.Provider
            value={{
                products: productsState.products,
                cart: cartState.cart,
                reducers: {
                    addProductToCart: addProductToCart,
                    removeProductFromCart: removeProductFromCart,
                    increaseProduct: increaseProduct,
                    decreaseProduct: decreaseProduct,
                    clearAll: clearAll,
                    fetchProducts: fetchProducts
                }
            }} >
            { props.children}
        </ShopContext.Provider>
    );
}

export default GlobalState;