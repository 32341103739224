import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// layouts
import MainLayout from './layouts/MainLayout'

// pages
import Homepage from './pages/home/Homepage'
import Terms from './pages/terms';
import Store from './pages/store/Store';
import ProductDetails from './pages/store/ProductDetails';
import Cart from './pages/cart/Cart'
import Payment from './pages/payment/Payment'
// import PaymentConfirmation from './pages/checkout/PaymentConfirmation';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// context
import GlobalState from './context/GlobalState'

window.onunload = () => {
  window.localStorage.clear()
}

const App = props => {

  return (
    <div className="App">
      <GlobalState>
        <Router>
          <Switch>
            <Route exact path="/" render={() => (
              <MainLayout>
                <Homepage />
              </MainLayout>
            )}
            />
            {/* <Route exact path="/About"><About /></Route> */}
            <Route exact path="/store/:category" render={() => (
              <MainLayout>
                <Store />
              </MainLayout>
            )} />
            <Route exact path="/store/:category/:product" render={() => (
              <MainLayout>
                <ProductDetails />
              </MainLayout>
            )} />
            <Route exact path="/cart" render={() => (
              <MainLayout>
                <Cart />
              </MainLayout>
            )}/>
            <Route exact path="/payment" render={() => (
              <MainLayout>
                <Payment />
              </MainLayout>
            )} />
            <Route exact path="/terms" render={() => (
              <MainLayout>
                <Terms />
              </MainLayout>
            )} />

          </Switch>
        </Router>
      </GlobalState>
    </div>
  )
}

export default App;
