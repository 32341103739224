export const PRODUCTSACTIONS = {
    FETCH_PRODUCTS: 'fetch_products'
}

export const ProductsReducer = (productsState, action) => {

    switch (action.type) {
        case PRODUCTSACTIONS.FETCH_PRODUCTS:
            return { ...productsState, products: [...productsState.products, { subcategory: action.payload.category, products: action.payload.products }] }
        default:
            return productsState

    }
}