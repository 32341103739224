import React, { useContext } from 'react';
import { ShopContext } from '../../context/ShopContext';

const CartItem = ({ product }) => {

    const { reducers } = useContext(ShopContext)

    console.log(product)

    return (
        <div className="row cart-item">
            <div className="col sm-2 p-2 cart-item-image">
                <img
                    alt={product.name}
                    style={{ margin: "0 auto", maxWidth: '250px', height: "auto" }}
                    src={product.src} className="img-fluid" />
            </div>
            <div className="col sm-4 p-2">
                <h5 className="mb-1">{product.name}</h5>
                <p className="mb-1">Price: {product.price} €</p>
                <p>Size: {product.selectedSize}</p>
            </div>
            <div className="col sm-4 p-2">
                <p className="mb-1">Qty: {product.quantity}</p>
                <div className="d-inline-flex">
                    <button
                        onClick={() => reducers.increaseProduct(product)}
                        className="btn-cart-more mr-3 d-flex justify-content-center align-items-center">
                        <ion-icon name="add-circle-outline"></ion-icon>
                    </button>

                    {
                        product.quantity > 1 &&
                        <button
                            onClick={() => reducers.decreaseProduct(product)}
                            className="btn-cart-less d-flex justify-content-center align-items-center">
                            <ion-icon name="remove-circle-outline"></ion-icon>
                        </button>
                    }

                    {
                        product.quantity === 1 &&
                        <button
                            onClick={() => reducers.removeProductFromCart(product)}
                            className="btn-cart-less d-flex justify-content-center align-items-center">
                            <ion-icon name="trash-outline"></ion-icon>
                        </button>
                    }
                </div>


            </div>
        </div>
    );
}

export default CartItem;