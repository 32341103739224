import React from 'react'
import ProductsGrid from './ProductsGrid';
import CustomWigs from '../../components/CustomWigs'

import './Store.css'

const Shop = props => {

    const subcategory = window.location.pathname.replace('/Store/', '')

    return (
        <div className="Shop">
            <div className="title-section">
                <h1>{subcategory.replace('_', ' ')}</h1>
            </div >
            <div className="products-section">
                {subcategory !== 'Custom_Wigs' ?
                    <ProductsGrid type={window.location.pathname.replace('/Store/', '')} />
                    : <CustomWigs />
                }
            </div>
        </div >
    )
}


export default Shop