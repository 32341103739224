import React from 'react'

import './index.css'

const CustomWigs = () => {

    return (
        <div className="container">
            <img className="cw-image" alt="custom_wigs" src="/customwigs.jpg"/>
            <h3>Send an email to glamerald.es@gmail.com with your inquiries</h3>
        </div>
    )
}

export default CustomWigs