import React from 'react'
import {useHistory} from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import { categories } from '../../dummy'

import './Home.css'

const Home = props => {

    const history = useHistory()

    return (
        <div className="home">
            <div className="logo-section">
                {/* <div className="logo">Glamerald</div> */}
                <img src="logo_glamerald.png" alt="logo" width="350"></img>
            </div>
            <div className="mid-section">
                {categories.map((category, i) =>
                    <div key={i} className="col">
                        <Carousel className="carousel-home">
                            {category.subcategories.map((subcategory, id) => (
                                <Carousel.Item key={id} onClick={() => history.push(`/Store/${subcategory.name}`)}>
                                    <img className="block" src={subcategory.images[0]} alt={subcategory.name}></img>
                                    <Carousel.Caption>
                                        <h1>{subcategory.name.replace("_", " ")}</h1>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                )}
            </div>
        </div>
    )

}

export default Home