import React from 'react';

import { Card } from 'react-bootstrap';

const ProductItem = ({ product }) => {

    return (
        <Card body className='border-0'>
            <img className="img-product" src={product.images[0].src} alt={product.name} />
            <Card.Body>
                <h5 className="mb-1">{product.name}</h5>
                <p className="mb-1">Price: {product.price} €</p>
                
                <a href={`/Store/${product.category}/${product.id}`}>
                    <button className="btn-details">Details</button>
                </a>
            </Card.Body>
        </Card>
    );
}

export default ProductItem;